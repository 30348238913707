<template>
    <ErrorLayout
        :errorStatus="500"
    />
</template>

<script>
    import { ErrorLayout } from "../../components"

    export default {
        name: "Error500",
        components: {
            ErrorLayout,
        }
    }
</script>
